import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CreateBanner from "../components/createBanner"
import Layout from "../components/layout"
import ServiceCard from "../components/serviceCard"

import Seo from "gatsby-plugin-wpgraphql-seo"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpService(sort: { order: ASC, fields: menuOrder }) {
        edges {
          node {
            title
            services {
              text
              titleBackgroundSmall
              keywords {
                keyword
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      wpPage(id: { eq: "cG9zdDo3OTE3" }) {
        id
        title
        nodeType
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <Layout>
      <Seo post={data.wpPage} />
      <CreateBanner />
      <div className="mx-auto max-w-screen-2xl ">
        {data.allWpService.edges.map(({ node }, i) => {
          console.log(i)
          return (
            <div className="mb-10 mt-10 -translate-y-10">
              <ServiceCard
                serviceInfo={node}
                reverse={i % 2 === 0 ? true : false}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Services
