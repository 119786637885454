import React from "react"
import ImageLoader from "./imageLoader"
import PageLink from "./pageLink"
import StyledHeader from "./styledHeader"

const ServiceCard = ({ serviceInfo, reverse }) => {
  return (
    <div className="lg:border-black lg:border-b-2 lg:border-l-2 lg:grid lg:grid-cols-2">
      <div
        className={
          " " +
          (reverse ? "lg:order-1" : "lg:order-2 lg:border-r-2 border-black")
        }
      >
        <div>
          <div className="p-6 lg:pb-0 lg:pt-0 lg:h-20 lg:border-black lg:border-b-2 lg:border-t-2">
            <div className="lg:-mt-7 ">
              <div>
                <StyledHeader
                  Tag="h2"
                  backgroundText={
                    serviceInfo.services.titleBackgroundSmall
                      ? serviceInfo.services.titleBackgroundSmall
                      : serviceInfo.title.split(" ")[0]
                  }
                  split
                  noWrap
                >
                  {serviceInfo.title}
                </StyledHeader>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3 lg:h-[calc(100%-5rem)]">
          <div className="border-black border-b-2 lg:border-b-0 p-6 lg:order-3 lg:col-span-2 lg:relative">
            {serviceInfo.services.text}
            <div className="hidden lg:block lg:absolute h-full lg:top-0 lg:right-0 lg:w-52 ">
              <div className="right-0 absolute lg:bottom-0 lg:p-6">
                <PageLink url="/contact">let's chat</PageLink>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 lg:block lg:border-r-2  border-black p-6 lg:order-1">
            <div className="">
              <ul>
                {serviceInfo.services.keywords.map((item, i) => {
                  return <li key={i}>{item.keyword}</li>
                })}
              </ul>
            </div>
            <div className="relative lg:hidden">
              <div className="absolute bottom-0 right-5 ">
                <PageLink url="/contact">let's chat</PageLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        // style={{ objectFit: "cover" }}
        className={
          "border-black lg:border-l-2 lg:border-r-2 lg:border-t-2 -z-20 object-cover " +
          (reverse ? "lg:order-2 lg:border-l-2" : "lg:order-1 lg:border-l-0")
        }
      >
        <ImageLoader
          image={serviceInfo.services.image}
          className="border-black border-2 lg:border-0 object-cover max-h-[500px] h-[100%]"
          objectFit="cover"
          imgStyle={{ objectFit: "cover" }}
          // className="object-cover w-full"
        />
      </div>
    </div>
  )
}

export default ServiceCard
